<script>
import MenuItemService from '@/service/menu-item.service';
import MenuService from '@/service/menu.service';
import cloneDeep from 'lodash/cloneDeep';

export default {
  name: 'modal-new-menu-item',

  data() {
    return {
      dialog: false,
      loading: false,
      loadingSave: false,
      form: { items: [] },

      entries: [],
      isLoading: false,
      search: null,
    };
  },

  computed: {
    items() {
      return this.entries.map((entry) => ({
        name: `${entry.name} ${entry.calorific_value} cal - ${entry.protein} proteína (por porção)`,
        id: entry.id,
      }));
    },
  },

  watch: {
    async search() {
      await this.fetchMenuItem();
    },
  },

  created() {
    this.$on('create', this.create);
    this.$on('edit', this.edit);
  },

  methods: {
    async create() {
      this.clearForm();
      await this.fetchMenuItem();
      this.dialog = true;
    },

    clearForm() {
      this.form = {};
    },

    async edit(id) {
      this.fetch(id);
      await this.fetchMenuItem();
      this.dialog = true;
    },

    async fetchMenuItem() {
      if (this.isLoading) return;
      this.isLoading = true;
      const { data } = await MenuItemService.find({
        search: this.search,
      });
      this.entries = data;
      this.isLoading = false;
    },

    async fetch(id) {
      this.loading = true;

      try {
        const menu = await MenuService.get(id);
        menu.items = menu.items.map((item) => item.id);
        this.form = cloneDeep(menu);
      } catch (e) {
        console.log(e);
      }

      this.loading = false;
    },

    async save() {
      this.loadingSave = true;
      try {
        const form = cloneDeep(this.form);
        if (form.id) {
          const id = this.form.id;
          delete form.id;
          await MenuService.update(id, form);
        } else {
          await MenuService.create(form);
        }
        this.dialog = false;
        this.$swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Salvo com sucesso!',
          timer: 2000,
          showConfirmButton: false,
        });
        this.$emit('success');
      } catch (e) {
        console.error(e);
      }
      this.loadingSave = false;
    },

    handleCancel() {
      this.dialog = false;
      this.clearForm();
    },
  },
};
</script>

<template>
  <v-dialog v-model="dialog" persistent scrollable max-width="525px">
    <v-card :loading="loading">
      <v-card-title>
        <span class="text-h5">{{ !form.id ? 'Cadastro' : 'Edição' }} de Cardápio</span>
      </v-card-title>
      <v-card-text>
        <v-col cols="12">
          <v-text-field
            v-model="form.name"
            label="Nome"
            color="white"
            hide-details="auto"
            outlined
            persistent-placeholder
            dense
          />
        </v-col>

        <v-col cols="12">
          <v-autocomplete
            v-model="form.items"
            clearable
            multiple
            :items="items"
            :loading="isLoading"
            :search-input.sync="search"
            placeholder="Itens do cardápio"
            item-text="name"
            item-value="id"
          ></v-autocomplete>
        </v-col>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="handleCancel"> Cancelar </v-btn>
        <v-btn color="success" :loading="loadingSave" @click="save"> Salvar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss">
.v-tabs-items {
  background-color: transparent !important;
}

.container-tab {
  min-height: 600px;
}

.registration-container {
  width: 100%;
  height: 100%;

  .registration-box {
    background-color: rgba(255, 255, 255, 0.1);
    border: 2px dashed rgba(255, 255, 255, 0.15);
  }
}
</style>
