import api from './api';

class MenuItemService {
  get(id) {
    return api.get(`/menu-item/${id}`).then((res) => res.data);
  }

  find(params) {
    return api.get('/menu-item', { params }).then((res) => res.data);
  }

  create(data) {
    return api.post(`/menu-item`, data).then((res) => res.data);
  }

  update(id, data) {
    return api.patch(`/menu-item/${id}`, data).then((res) => res.data);
  }

  delete(id) {
    return api.delete(`/menu-item/${id}`).then((res) => res.data);
  }
}

export default new MenuItemService();
